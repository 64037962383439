import React from 'react';
import {Card} from 'react-bootstrap';
import {WorkerDataPage} from "../../../type/WorkerData";
import Loading from "../../controls/Loading";
import WorkerStateControl from "../../controls/WorkerStateControl";
import {StringUtil} from "incomaker-react-ts-commons";
import DurationControl from "../../controls/DurationControl";
import {getSinceDurationMs} from "../../../util/dateUtil";

export type WorkersOverviewProps = {
	workers: WorkerDataPage | null;
};

function WorkersOverview({workers}: WorkersOverviewProps) {
	if (workers === null) return <Loading/>;

	if (workers.totalElements === 0) return <span>No workers available.</span>

	return (
		<div>
			<div className="d-flex flex-wrap gap-3">
				{
					workers.content.map(
						(w) =>
							<Card key={w.name} className="text-center">
								<Card.Header>
									<div>
										<strong>{w.name}</strong>
									</div>
									<div>
										<small><code>{w.version}</code></small>
									</div>
								</Card.Header>
								<Card.Body>
									<div className="d-flex flex-column text-center align-items-center gap-2">
										<small>
											{
												w.workerState === 'Dead' ? (
													<>
														<span>Died&nbsp;</span>
														<span><DurationControl ms={getSinceDurationMs(w.lastHeartbeat)}/>&nbsp;</span>
														<span>ago</span>
														{
															StringUtil.notEmpty(w.currentActivity) && w.currentActivity !== 'Zzz...' && (
																<span>&nbsp;when "{w.currentActivity}"</span>
															)
														}
													</>
												) : (
													<>{w.currentActivity}</>
												)
											}
										</small>
									</div>
								</Card.Body>
								<Card.Footer>
									<div className="d-flex align-items-center">
										<WorkerStateControl state={w.workerState}/>
									</div>
								</Card.Footer>
							</Card>
					)
				}
			</div>
		</div>
	);
}

export default WorkersOverview;
