import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {loadActiveSchedules, loadRunningExecutions, loadWorkers} from "../../../util/restClient";
import {SchedulePage} from "../../../type/ScheduleData";
import {ExecutionDataPage} from "../../../type/ExecutionData";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import ActiveSchedulesGrid from "./ActiveSchedulesGrid";
import RunningExecutionsGrid from "./RunningExecutionsGrid";
import WorkersOverview from "./WorkersOverview";
import {WorkerDataPage} from "../../../type/WorkerData";
import RabbitQueueControl from "./RabbitQueueControl";

function DashboardTab({eventManager}: BasicComponentProps) {
	const [workers, setWorkers] = useState<WorkerDataPage | null>(null);
	const [activeSchedules, setActiveSchedules] = useState<SchedulePage | null>(null);
	const [runningExecutions, setRunningExecutions] = useState<ExecutionDataPage | null>(null);

	const refreshWorkers = () => {
		loadWorkers({size: 10, page: 0, sorting: [{name: "name"}]})
			.then(
				setWorkers,
				(err) => eventManager.err(String(err))
			);
	};

	const refreshActiveSchedules = () => {
		loadActiveSchedules()
			.then(
				setActiveSchedules,
				(err) => eventManager.err(String(err))
			);
	};

	const refreshRunningExecutions = () => {
		loadRunningExecutions()
			.then(
				setRunningExecutions,
				(err) => eventManager.err(String(err))
			);
	};

	const refreshAll = () => {
		refreshWorkers();
		refreshRunningExecutions();
		refreshActiveSchedules();
	}

	useEffect(() => {
		refreshAll();
		const intervalHandle: NodeJS.Timeout = setInterval(refreshAll, 3000);
		return () => {
			window.clearInterval(intervalHandle);
		};
	}, []);

	return (
		<div>
			<Row>
				<Col>
					<div className="mt-3">
						<div className="d-flex justify-content-between mb-2">
							<h3>Workers</h3>
						</div>
						<WorkersOverview workers={workers}/>
					</div>
				</Col>
				<Col sm={12} md={6} lg={3}>
					<div className="mt-3">
						<div className="d-flex justify-content-between mb-2">
							<h3>Message Queues</h3>
						</div>
						<div className="d-flex flex-wrap gap-3">
							<RabbitQueueControl queueName="import.plugin" eventManager={eventManager}/>
							<RabbitQueueControl queueName="import.plugin.vip" eventManager={eventManager}/>
						</div>
					</div>
				</Col>
			</Row>
			<div className="mt-3">
				<div className="d-flex justify-content-between align-items-center mb-2">
					<h3>Running Executions</h3>
				</div>
				<RunningExecutionsGrid executions={runningExecutions} eventManager={eventManager}/>
			</div>
			<div className="mt-3">
				<div className="d-flex justify-content-between mb-2">
					<h3>Scheduling</h3>
				</div>
				<ActiveSchedulesGrid schedules={activeSchedules}/>
			</div>
		</div>
	);
}

export default DashboardTab;
