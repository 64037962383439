import React, {useEffect, useState} from 'react';
import {loadMessages} from "../../../util/restClient";
import {BasicComponentProps} from "../../../type/BasicComponentProps";
import {DEFAULT_PAGING, Paging} from "../../../type/Paging";
import {clone} from "../../../util/objectUtil";
import TabMenu from "../../controls/TabMenu";
import AdvancedTable from "../../controls/AdvancedTable";
import Loading from "../../controls/Loading";
import {formatDateForHumans} from "../../../util/dateUtil";
import {getCache, setCache} from "../../../util/cacheUtil";
import {ExecutionMessageDataPage} from "../../../type/ExecutionMessageData";
import MessageTypeControl from "../../controls/execution/message/MessageTypeControl";

const DEFAULT_PAGING_MESSAGES: Paging = clone(DEFAULT_PAGING);
DEFAULT_PAGING_MESSAGES.sorting = [{name: "timestamp", desc: true}];

function MessagesTab({eventManager}: BasicComponentProps) {
	const defaultSearchText = getCache('default-search-text', () => '');
	const cachedPaging = getCache('paging-props-messages', () => clone(DEFAULT_PAGING_MESSAGES));
	cachedPaging.search = defaultSearchText;
	const [paging, setPaging] = useState<Paging>(cachedPaging);

	const [messages, setMessages] = useState<ExecutionMessageDataPage | null>(null);

	const search = (text: string) => {
		const newPaging = clone(paging);
		newPaging.search = text;
		setCache('paging-props-messages', newPaging);
		setCache('default-search-text', text)
		setPaging(newPaging);
	}

	const refreshMessages = () => {
		setMessages(null);
		loadMessages(paging)
			.then(
				setMessages,
				(err) => eventManager.err(String(err))
			);
	}

	useEffect(refreshMessages, [paging]);

	const header = [
		{name: 'executionId', label: 'Execution'},
		{name: 'timestamp', label: 'Time'},
		{name: 'messageType', label: 'Type'},
		{name: 'message', label: 'Message'}
	];

	return (
		<div>
			<TabMenu onRefreshButtonClick={refreshMessages}>
			</TabMenu>
			<AdvancedTable
				header={header}
				onPagingChanged={setPaging}
				totalPages={messages ? messages.totalPages : 0}
				totalItems={messages ? messages.totalElements : 0}
				paging={paging}
			>
				{
					(messages === null) ? <tr key="none">
							<td colSpan={header.length}><Loading/></td>
						</tr> :
						messages.content.map((message, index) => {
							return (
								<tr key={index}>
									<td>{message.executionId}</td>
									<td>{formatDateForHumans(message.timestamp)}</td>
									<td><MessageTypeControl typ={message.messageType}/></td>
									<td>{message.message}</td>
								</tr>
							);
						})
				}
			</AdvancedTable>
		</div>
	);
}

export default MessagesTab;
